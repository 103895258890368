<template>
  <div>
    <el-radio-group v-model="moqStatus" style="margin-left:55px" class="mb-3" @change="handleChange">
      <el-radio-button v-for="(item,index) in _getAllCommodityDict('DSB_MOQ_STATE')" :key="index" :label="item.val">{{ item.label }}</el-radio-button>
    </el-radio-group>
    <QueryForm
      v-model="queryForm"
      :show-export="isEffective"
      :query-btn-loading="tableLoading"
      @handleExport="handleExport"
      @query="handleQuery"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="供应商">
        <el-select v-model="queryForm.vendorIds" clearable filterable multiple>
          <el-option
            v-for="item in vendorOptions"
            :key="item.id"
            :label="item.vendorName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="单款默认MOQ">
        <InputRange
          v-model="queryForm.styleMoq"
          :disabled="!isEffective"
          :precision="0"
          :user-input-to-max="999999"
          :user-input-form-min="0"
        />
      </el-form-item>
      <el-form-item label="单色默认MOQ">
        <InputRange
          v-model="queryForm.colorMoq"
          :disabled="!isEffective"
          :precision="0"
          :user-input-to-max="999999"
          :user-input-form-min="0"
        />
      </el-form-item>
      <el-form-item label="单位">
        <Select
          v-model="queryForm.unitList"
          :select-options="_getAllCommodityDict('DSB_MOQ_UNIT')"
          clearable
          multiple
          :disabled="!isEffective"
        />
      </el-form-item>
      <el-form-item label="操作人" :class="$i18n.locale">
        <Select
          v-model="queryForm.modifyByIds"
          api-key="UsersList"
          clearable
          multiple
          :disabled="!isEffective"
        />
      </el-form-item>
      <el-form-item label="操作时间" :class="$i18n.locale">
        <el-date-picker
          v-model="queryForm.value"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="~"
          :disabled="!isEffective"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
    </QueryForm>
    <div>
      <el-button class="mr-3" type="primary" @click="importDialogVisible=true">批量导入</el-button>
      <span style="font-size:14px;color:#606266">MOQ：单次采购最小订货量</span>
    </div>
    <!-- <Table
      check
      :table-data="tableData"
      max-height="600px"
      class="mt-3"
      :columns="MoqManageColumns"
      :loading="tableLoading"
    >
      <el-table-column slot="operate" label="操作" width="120px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.state==='0'" type="text" @click="handleAdd(scope.row,'add','新增')">配置MOQ</el-button>
          <el-button v-if="scope.row.state==='1'" type="text" @click="handleAdd(scope.row,'edit','修改')">修改</el-button>
        </template>
      </el-table-column>
    </Table> -->
    <vxe-table
      class="mt-3"
      :scroll-y="{ enabled: true, gt: 20 }"
      :scroll-x="{ enabled: true, gt: 20 }"
      max-height="600px"
      :loading="tableLoading"
      align="center"
      highlight-hover-row
      :data="tableData"
      auto-resize
      resizable
      @checkbox-all="(({records})=>multipletable=records)"
      @checkbox-change="(({records})=>multipletable=records)"
    >
      <vxe-table-column type="checkbox" width="50" />
      <vxe-table-column width="80" type="seq" title="序号" />
      <vxe-table-column
        min-width="150"
        field="vendorName"
        title="供应商"
      >
        <template v-slot="{ row }">
          <el-button type="text" @click="handleAdd(row, 'view', '查看')">{{ row.vendorName }}</el-button>
        </template>
      </vxe-table-column>
      <vxe-table-column min-width="120" field="styleMoq" title="单款默认MOQ" />
      <vxe-table-column min-width="120" field="colorMoq" title="单色默认MOQ" />
      <vxe-table-column min-width="120" field="unit_zh" title="单位" />
      <vxe-table-column min-width="120" field="state_zh" title="状态" />
      <vxe-table-column min-width="120" field="remark" title="备注说明" />
      <vxe-table-column min-width="120" field="modifyByName" title="操作人" />
      <vxe-table-column min-width="180" field="modifyTime" title="操作时间" />
      <vxe-table-column
        min-width="100"
        title="操作"
      >
        <template v-slot="{ row }">
          <el-button v-if="row.state==='0'" type="text" @click="handleAdd(row,'add','新增')">配置MOQ</el-button>
          <el-button v-if="row.state==='1'" type="text" @click="handleAdd(row,'edit','修改')">修改</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <Paging v-if="isEffective" :pager="pager" end @pagination="pagerUpdate" />
    <Import v-model="importDialogVisible" @refresh="handleQuery" />
    <Moq v-model="moqDialogVisible" :dialog-title="dialogTitle" :dialog-type="dialogType" :current-row="currentRow" @update="handleQuery" />

  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import { pageEffective, pageWait, moqExportData } from '@/api/configuration'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import InputRange from '@/components/InputRange'
import Import from './components/Import'
import Moq from './components/Moq'
import { handlePageParams } from '@/utils'
import Paging from '@/components/Pagination'
import { queryVendor } from '@/api/blurbInformation.js'

export default {
  components: { Moq, QueryForm, Select, InputRange, Import, Paging },
  mixins: [commodityInfoDict],
  data() {
    return {
      multipletable: [],
      currentRow: {},
      dialogTitle: '',
      dialogType: '',
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      moqDialogVisible: false,
      importDialogVisible: false,
      // MoqManageColumns: [
      //   { label: '供应商', prop: 'vendorName',
      //     formatter: (row) => {
      //       return <el-button onClick={() => this.handleAdd(row, 'view', '查看')} type='text'>{row.vendorName}</el-button>
      //     } },
      //   { label: '单款默认MOQ', prop: 'styleMoq' },
      //   { label: '单色默认MOQ', prop: 'colorMoq' },
      //   { label: '单位', prop: 'unit' },
      //   { label: '状态', prop: 'state_zh' },
      //   { label: '备注说明', prop: 'remark' },
      //   { label: '操作人', prop: 'modifyByName' },
      //   { label: '操作时间', prop: 'modifyTime' },
      //   { slot: 'operate' }
      // ],
      tableData: [],
      queryForm: { },
      moqStatus: '0',
      tableLoading: false,
      vendorOptions: []
    }
  },
  computed: {

    isEffective() {
      return this.moqStatus === '1'
    },
    queryParameter() {
      return handlePageParams({
        formData: this.queryForm,
        processedParameter: {
          timeField: {
            value: ['modifyTimeStart', 'modifyTimeEnd'],
            colorMoq: ['colorMoqStart', 'colorMoqEnd'],
            styleMoq: ['styleMoqStart', 'styleMoqEnd']
          }
        }
      })
    }
  },
  mounted() {
    this.handleQuery()
    this._queryVendor()
  },
  methods: {
    async _queryVendor() {
      const { datas: { records }} = await queryVendor({}, { current: 1, size: 999999, total: 0 })
      this.vendorOptions = records
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    handleChange() {
      // 待配置时清空其他信息
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    async handleExport() {
      let idList = []
      if (this.multipletable.length) {
        idList = this.multipletable.map(v => v.id)
      }
      const params = Object.assign({}, { idList }, this.queryParameter)
      await moqExportData(params)
      this.$notify({
        message: '操作成功，请前往文件导出界面下载',
        type: 'success'
      })
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    async handleQuery() {
      try {
        this.tableLoading = true

        const result = this.isEffective
          ? await pageEffective(this.pager, this.queryParameter)
          : await pageWait(this.queryParameter)

        this.tableData = this.isEffective ? result.datas.records : result.datas

        if (this.isEffective) {
          const { total, size, current } = result.datas
          this.pager = { total, size, current }
        }
      } finally {
        this.tableLoading = false
      }
    },
    async handleAdd(row, type, title) {
      this.dialogTitle = title
      this.dialogType = type
      this.currentRow = row
      console.log(' this.currentRow: ', this.currentRow)
      this.moqDialogVisible = true
    }

  }
}
</script>

<style>

</style>

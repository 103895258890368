import request from '@/utils/request'
/**
 *
 * @description 第三方管理
 * @export Promise
 * @returns Json
 */
// const basicdataApi = process.env.VUE_APP_CLIENT_API
// 供应商信息查询
export function queryVendor(data, pager) {
  return request({
    url: `/base/basevendor/page/?current=${pager.current}&size=${pager.size}&total=${pager.total}`,
    method: 'post',
    data
  })
}

// 供应商息添加
export function addVendor(data) {
  return request({
    url: `/base/basevendor`,
    method: 'post',
    data
  })
}
// 供应商信息修改
export function modifyVendor(data) {
  return request({
    url: `/base/basevendor`,
    method: 'put',
    data
  })
}
// 供应商信息通过id查询
export function queryVendorId(id) {
  return request({
    url: `/base/basevendor/${id}`,
    method: 'get'
  })
}
/**
 *
 * @description 盒子管理
 * @export Promise
 * @returns Json
 */
// const basicdataApi = process.env.VUE_APP_CLIENT_API
// 盒子信息查询
export function querykingbox(params) {
  return request({
    url: `/base/basepackingbox/page`,
    method: 'get',
    params
  })
}
// 盒子信息删除
export function deletekingbox(id) {
  return request({
    url: `/base/basepackingbox/${id}`,
    method: 'delete'
  })
}
// 盒子信息添加
export function addkingbox(data) {
  return request({
    url: `/base/basepackingbox`,
    method: 'post',
    data
  })
}
// 盒子信息修改
export function modifykingbox(data) {
  return request({
    url: `/base/basepackingbox`,
    method: 'put',
    data
  })
}
// 盒子信息通过id查询
export function querykingboxId(id) {
  return request({
    url: `/base/basepackingbox/${id}`,
    method: 'get'
  })
}
// 盒子信息列表
export function querykingboxList() {
  return request({
    url: `/base/basepackingbox/list`,
    method: 'get'
  })
}
/**
 *
 * @description 箱规管理
 * @export Promise
 * @returns Json
 */
// 箱规信息查询
export function queryCarton(params) {
  return request({
    url: `/base/basecartonspecification/page`,
    method: 'get',
    params
  })
}
// 箱规信息列表
export function queryCartonList() {
  return request({
    url: `/base/basecartonspecification/list`,
    method: 'get'
  })
}
// 箱规信息删除
export function deleteCarton(id) {
  return request({
    url: `/base/basecartonspecification/${id}`,
    method: 'delete'
  })
}
// 箱规信息添加
export function addCarton(data) {
  return request({
    url: `/base/basecartonspecification`,
    method: 'post',
    data
  })
}
// 箱规信息修改
export function modifyCarton(data) {
  return request({
    url: `/base/basecartonspecification`,
    method: 'put',
    data
  })
}
// 箱规信息通过id查询
export function queryCartonId(id) {
  return request({
    url: `/base/basecartonspecification/${id}`,
    method: 'get'
  })
}
/**
 *
 * @description 颜色管理
 * @export Promise
 * @returns Json
 */
// 颜色信息查询
export function querycolor(params) {
  return request({
    url: `/base/basecolor/page`,
    method: 'get',
    params
  })
}
// 颜色信息添加
export function addcolor(data) {
  return request({
    url: `/base/basecolor`,
    method: 'post',
    data
  })
}
// 颜色信息修改
export function modifycolor(data) {
  return request({
    url: `/base/basecolor`,
    method: 'put',
    data
  })
}
// 颜色信息通过id查询
export function querycolorId(id) {
  return request({
    url: `/base/basecolor/${id}`,
    method: 'get'
  })
}
/**
 *
 * @description 尺码管理
 * @export Promise
 * @returns Json
 */
// Size信息查询
export function querySize(params) {
  return request({
    url: `/base/basesize/page`,
    method: 'get',
    params
  })
}
// Size信息添加
export function addSize(data) {
  return request({
    url: `/base/basesize`,
    method: 'post',
    data
  })
}
// Size信息修改
export function modifySize(data) {
  return request({
    url: `/base/basesize`,
    method: 'put',
    data
  })
}
// Size信息通过id查询
export function querySizeId(id) {
  return request({
    url: `/base/basesize/${id}`,
    method: 'get'
  })
}
/**
 *
 * @description 品牌管理
 * @export Promise
 * @returns Json
 */
// 品牌信息查询
export function queryBrand(params) {
  return request({
    url: `/base/basebrand/page`,
    method: 'get',
    params
  })
}
// 品牌信息添加
export function addBrand(data) {
  return request({
    url: `/base/basebrand`,
    method: 'post',
    data
  })
}
// 品牌信息修改
export function modifyBrand(data) {
  return request({
    url: `/base/basebrand`,
    method: 'put',
    data
  })
}
// 品牌信息通过id查询
export function queryBrandId(id) {
  return request({
    url: `/base/basebrand/${id}`,
    method: 'get'
  })
}
// 品牌信息删除
export function deleteBrand(id) {
  return request({
    url: `/base/basebrand/${id}`,
    method: 'delete'
  })
}

/**
 *
 * @description style管理
 * @export Promise
 * @returns Json
 */

// style管理信息查询
export function queryStyle(params) {
  return request({
    url: `/base/basestyle/page`,
    method: 'get',
    params
  })
}

// style信息添加
export function addStyle(data) {
  return request({
    url: `/base/basestyle`,
    method: 'post',
    data
  })
}
// style信息修改
export function modifyStyle(data) {
  return request({
    url: `/base/basestyle`,
    method: 'put',
    data
  })
}
// style信息通过id查询
export function queryStyleId(id) {
  return request({
    url: `/base/basestyle/${id}`,
    method: 'get'
  })
}
// style信息通过条件查询
export function queryStyleList(params) {
  return request({
    url: `/base/basestyle/list`,
    method: 'get',
    params
  })
}
/**
 *
 * @description 材料信息
 * @export Promise
 * @returns Json
 */

// 材料信息查询
export function queryMaterials(params) {
  return request({
    url: `/base/basematerials/page`,
    method: 'get',
    params
  })
}
export function queryMaterialsList(params) {
  return request({
    url: `/base/basematerials/list`,
    method: 'get',
    params
  })
}
// 材料信息添加
export function addMaterials(data) {
  return request({
    url: `/base/basematerials`,
    method: 'post',
    data
  })
}
// 材料信息修改
export function modifyMaterials(data) {
  return request({
    url: `/base/basematerials`,
    method: 'put',
    data
  })
}
// 材料信息通过id查询
export function queryMaterialsId(id) {
  return request({
    url: `/base/basematerials/${id}`,
    method: 'get'
  })
}

/**
 *
 * @description 分类管理
 * @export Promise
 * @returns Json
 */
// 条件查询
export function queryCategorylist(params) {
  return request({
    url: `/base/basecategory/list`,
    method: 'get',
    params
  })
}
// 分类信息查询
export function queryCategory(params) {
  return request({
    url: `/base/basecategory/page`,
    method: 'get',
    params
  })
}
// 分类信息添加
export function addCategory(data) {
  return request({
    url: `/base/basecategory`,
    method: 'post',
    data
  })
}
// 分类信息修改
export function modifyCategory(data) {
  return request({
    url: `/base/basecategory`,
    method: 'put',
    data
  })
}
// 分类信息通过id查询
export function queryCategoryId(id) {
  return request({
    url: `/base/basecategory/${id}`,
    method: 'get'
  })
}
// 分类信息删除
export function deleteCategory(id) {
  return request({
    url: `/base/basecategory/${id}`,
    method: 'delete'
  })
}
/**
 *
 * @description SKU其他信息维护
 * @export Promise
 * @returns Json
 */
// SKU其他信息维护查询
export function querySkurests(params) {
  return request({
    url: `/base/baseskurests/page`,
    method: 'get',
    params
  })
}
// SKU其他信息维护添加
export function addSkurests(data) {
  return request({
    url: `/base/baseskurests`,
    method: 'post',
    data
  })
}
// SKU其他信息维护修改
export function modifySkurests(data) {
  return request({
    url: `/base/baseskurests`,
    method: 'put',
    data
  })
}
// SKU其他信息维护通过id查询
export function querySkurestsId(id) {
  return request({
    url: `/base/baseskurests/${id}`,
    method: 'get'
  })
}

/**
 *
 * @description SKU材料组件管理
 * @export Promise
 * @returns Json
 */
// SKU材料组件管理查询
export function querySkumaterialscomp(params) {
  return request({
    url: `/base/baseskumaterialscomp/page`,
    method: 'get',
    params
  })
}
// SKU材料组件条件查询
export function querySkumaterialscompList(params) {
  return request({
    url: `/base/baseskumaterialscomp/list`,
    method: 'get',
    params
  })
}
// SKU材料组件管理添加
export function addSkumaterialscomp(data) {
  return request({
    url: `/base/baseskumaterialscomp`,
    method: 'post',
    data
  })
}
// SKU材料组件管理修改
export function modifySkumaterialscomp(data) {
  return request({
    url: `/base/baseskumaterialscomp`,
    method: 'put',
    data
  })
}
// SKU材料组件管理通过id查询
export function querySkumaterialscompId(id) {
  return request({
    url: `/base/baseskumaterialscomp/${id}`,
    method: 'get'
  })
}
/**
 *
 * @description SKU管理
 * @export Promise
 * @returns Json
 */
// SKU管理查询
export function querySku(params) {
  return request({
    url: `/base/basesku/page`,
    method: 'get',
    params
  })
}
// SKU管理添加
export function addSku(data) {
  return request({
    url: `/base/basesku`,
    method: 'post',
    data
  })
}
// SKU管理修改
export function modifySku(data) {
  return request({
    url: `/base/basesku`,
    method: 'put',
    data
  })
}
// SKU管理通过id查询
export function querySkuId(id) {
  return request({
    url: `/base/basesku/${id}`,
    method: 'get'
  })
}
// SKU管理通过一级分类获取动态属性
export function queryAttrId(categoryId) {
  return request({
    url: `/base/basesku/queryAttr/${categoryId}`,
    method: 'get'
  })
}

/**
 *
 * @description 平台SKU管理
 * @export Promise
 * @returns Json
 */
// 平台SKU条件查询
export function queryBasefnskuList(params) {
  return request({
    url: `/base/basefnsku/list`,
    method: 'get',
    params
  })
}
// 平台SKU查询
export function queryFnskuPlatform(params) {
  return request({
    url: `/base/basefnsku/page`,
    method: 'get',
    params
  })
}

// 平台SKU修改
export function modifyFnskuPlatform(data) {
  return request({
    url: `/base/basefnsku`,
    method: 'put',
    data
  })
}
// 平台SKU color 批量修改
export function modifyFnskuColor(data) {
  return request({
    url: `/base/basefnsku/updateBatchSkuColor`,
    method: 'put',
    data
  })
}
// 平台SKU 状态 批量修改
export function modifyFnskuStatus(data) {
  return request({
    url: `/base/basefnsku/updateBatchSkuStatus`,
    method: 'put',
    data
  })
}
// 平台SKU 信息通过id查询
export function queryfnskuId(fnskuId) {
  return request({
    url: `/base/basefnsku/${fnskuId}`,
    method: 'get'
  })
}
// 替换SKU信息
export function replacePlatSku(data) {
  return request({
    url: `/base/basefnsku/updatePlatSku`,
    method: 'put',
    data
  })
}
export function syncFnsku(data) {
  return request({
    url: `/base/basesku/syncFnsku`,
    method: 'post',
    data
  })
}
// // 获取UPC
export function queryUPC(number) {
  return request({
    url: `/base/baseupc/${number}`,
    method: 'get'
  })
}

// 修改图片信息
export function skuPic(data) {
  return request({
    url: `/base/basesku/pic`,
    method: 'put',
    data
  })
}
// 通过id获取原图片信息
export function skuGetPic(id) {
  return request({
    url: `/base/basesku/pic/${id}`,
    method: 'get'
  })
}
// 同步站点fnsku upc
export function syncFnskuUpc(data) {
  return request({
    url: `/base/basefnsku/sync`,
    method: 'post',
    data
  })
}

// 包装信息
export function packinginfoList() {
  return request({
    url: `/base/base/getPackageMaterialList`,
    method: 'get'
  })
}
// 根据供应商类型获取供应商信息
export function getVendorListByVendorType(params) {
  return request({
    url: `/base/basevendor/getVendorListByVendorType`,
    method: 'get',
    params
  })
}

export function getVendorAccountListByVendorId(data) {
  return request({
    url: `/base/baseVendorAccount/getVendorAccountListByVendorId`,
    method: 'post',
    data
  })
}
// /baseVendorAccount/getVendorAccountListByCargoOwnerCode
// 根据供应商id获取用户信息
export function getVendorAccountListByCargoOwnerCode(params) {
  return request({
    url: `base/baseVendorAccount/getVendorAccountListByCargoOwnerCode`,
    method: 'get',
    params
  })
}
// 查供应商已有更改信息在审核中,，datas返回true审核中，false无审核中
export function getSelectPending(params) {
  return request({
    url: `/base/basevendor/select/pending`,
    method: 'get',
    params
  })
}
// 下拉查询供应商
export function getVendorSelect(params) {
  return request({
    url: `/base/basevendor/select`,
    method: 'get',
    params
  })
}
// 下拉查询供应商
export function vendorOfferSelect(params) {
  return request({
    url: `/scm-purchase/vendorOffer/select/pending`,
    method: 'get',
    params
  })
}
// 查询供应商变更信息明细
export function baseVendorDetail(params) {
  return request({
    url: `/base/basevendor/detail`,
    method: 'get',
    params
  })
}

// 提交供应商信息变更
export function baseVendorSubmit(data) {
  return request({
    url: `/base/basevendor/update/submit`,
    method: 'post',
    data,
    timeout: 9999999
  })
}
// 获取人员信息
export function getAllDepartmentInfo(data) {
  return request({
    url: `/base/base/getAllDepartmentInfo`,
    method: 'post',
    data,
    timeout: 9999999
  })
}
// 查询供应商变更信息明细
export function getVendorChangeLogListByVendorId(params) {
  return request({
    url: `/base/baseVendorChangeLog/getVendorChangeLogListByVendorId`,
    method: 'get',
    params
  })
}
// 根据供应商id和币种获取账号信息
export function getVendorAccountListByVendorIdAndCurrency(params) {
  return request({
    url: `/base/baseVendorAccount/getVendorAccountListByVendorIdAndCurrency`,
    method: 'get',
    params
  })
}
// 根据货主Code和币种获取账号信息
export function getVendorAccountListByCargoOwnerCodeAndCurrency(params) {
  return request({
    url: `/base/baseVendorAccount/getVendorAccountListByCargoOwnerCodeAndCurrency`,
    method: 'get',
    params
  })
}


<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      :title="`${dialogTitle}MOQ配置`"
      center
      width="90%"
    >
      <el-form
        ref="formRef"
        v-loading="loading"
        :model="form"
        label-width="150px"
        :rules="rules"
      >
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="供应商">
              <Select
                v-model="form.vendorName"
                disabled
                :select-options="[]"
                clearable
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单款默认MOQ" prop="styleMoq">
              <el-input-number
                v-model="form.styleMoq"
                :min="1"
                :max="999999"
                :disabled="isViewPage"
                :precision="0"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="单色默认MOQ"
              prop="colorMoq"
              :rules="[{ required: true, message: '必填', trigger: ['blur'] },validateMaxColorMoq(form)]"
            >
              <el-input-number
                v-model="form.colorMoq"
                :min="1"
                :disabled="isViewPage"
                :max="999999"
                :precision="0"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" prop="unit">
              <Select
                v-model="form.unit"
                disabled
                style="width: 100%"
                :select-options="_getAllCommodityDict('DSB_MOQ_UNIT')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注说明" prop="remark">
              <el-input
                v-model="form.remark"
                :disabled="isViewPage"
                type="textarea"
                :maxlength="1000"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" justify="space-between">
        <el-col :span="12" class="mr-4">
          <div>
            特定款式：<el-button
              type="text"
              class="mr-3"
              :disabled="isViewPage"
              @click="handleAdd('style', 'styleList')"
            >添加</el-button>
            <el-button
              type="text"
              :disabled="isViewPage"
              @click="handleDelete(form.styleList, styleSelection, 'style')"
            >删除</el-button>
          </div>
          <el-card class="box-card">
            <div slot="header">
              <Select
                v-model="searchStyleNameList"
                placeholder="输入Style搜索"
                style="width: 100%"
                :select-options="styleOptions"
                :configuration="{
                  key: 'id',
                  label: 'styleName',
                  value: 'styleName',
                }"
                clearable
                multiple
              />
            </div>
            <el-form ref="styleFormRef" :model="form" :rules="rules">
              <el-table
                ref="xTableStyle"
                v-loading="loading"
                max-height="400px"
                border
                :data="
                  form.styleList.filter((v) =>
                    searchStyleNameList.length
                      ? searchStyleNameList.includes(v.styleName)
                      : true
                  )
                "
                align="center"
                style="width: 100%"
                @selection-change="(val) => (styleSelection = val)"
              >
                <el-table-column type="selection" width="55" />
                <el-table-column prop="styleName" label="Style" align="center">
                  <template #default="{ row, $index }">
                    <el-form-item
                      :prop="'styleList.' + $index + '.styleName'"
                      :rules="[
                        {
                          required: true,
                          message: $t('page.required'),
                          trigger: ['change', 'blur'],
                        },
                        validateSingleStyleName(
                          form.styleList,
                          'styleName',
                          '重复Style！'
                        ),
                      ]"
                    >
                      <el-select
                        v-model="row.styleName"
                        clearable
                        filterable
                        :disabled="isViewPage"
                        @change="(val) => getStyleId(val, row)"
                      >
                        <el-option
                          v-for="item in styleInsuseOptions"
                          :key="item.id"
                          :value="item.styleName"
                          :label="item.styleName"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="styleMoq"
                  align="center"
                  label="单款MOQ"
                  class="table"
                >
                  <template #default="{ row, $index }">
                    <el-form-item
                      :prop="'styleList.' + $index + '.styleMoq'"
                      :rules="[
                        {
                          required: true,
                          message: $t('page.required'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="row.styleMoq"
                        :disabled="isViewPage"
                        style="width: 100%"
                        :precision="0"
                        :controls="false"
                        :min="1"
                        :max="999999"
                        clearable
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="colorMoq"
                  label="单色MOQ"
                  align="center"
                  class="table"
                >
                  <template #default="{ row, $index }">
                    <el-form-item
                      :prop="'styleList.' + $index + '.colorMoq'"
                      :rules="[validateMaxColorMoq(row)]"
                    >
                      <el-input-number
                        v-model="row.colorMoq"
                        :disabled="isViewPage"
                        style="width: 100%"
                        :precision="0"
                        :controls="false"
                        :min="1"
                        :max="999999"
                        clearable
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </el-card>
        </el-col>
        <el-col :span="12">
          <div>
            特定颜色：<el-button
              type="text"
              class="mr-3"
              :disabled="isViewPage"
              @click="handleAdd('color', 'colorList')"
            >添加</el-button>
            <el-button
              :disabled="isViewPage"
              type="text"
              @click="handleDelete(form.colorList, colorSelection, 'color')"
            >删除</el-button>
          </div>
          <el-card class="box-card">
            <div slot="header">
              <Select
                v-model="searchColorNameList"
                placeholder="输入Color搜索"
                style="width: 100%"
                :select-options="colorOptions"
                :configuration="{
                  key: 'id',
                  label: 'colorName',
                  value: 'colorName',
                }"
                clearable
                multiple
              />
            </div>
            <el-form ref="colorFormRef" :model="form" :rules="rules">
              <el-table
                ref="xTableStyle"
                v-loading="loading"
                max-height="400px"
                border
                style="width: 100%"
                :data="
                  form.colorList.filter((v) =>
                    searchColorNameList.length
                      ? searchColorNameList.includes(v.colorName)
                      : true
                  )
                "
                align="center"
                @selection-change="(val) => (colorSelection = val)"
              >
                <el-table-column type="selection" width="55" />
                <el-table-column prop="colorName" label="Color" align="center">
                  <template #default="{ row, $index }">
                    <el-form-item
                      :prop="'colorList.' + $index + '.colorName'"
                      :rules="[
                        {
                          required: true,
                          message: $t('page.required'),
                          trigger: 'blur',
                        },
                        validateSingleStyleName(
                          form.colorList,
                          'colorName',
                          '重复Color！'
                        ),
                      ]"
                    >
                      <el-select
                        v-model="row.colorName"
                        :disabled="isViewPage"
                        clearable
                        filterable
                        @change="(val) => _queryMoqStyleList(val, row)"
                      >
                        <el-option
                          v-for="item in colorInsuseOptions"
                          :key="item.styleId"
                          :value="item.colorName"
                          :label="item.colorName"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="单色MOQ"
                  class="table"
                >
                  <template #default="{ row ,$index}">
                    <el-form-item
                      :prop="'colorList.' + $index + '.colorMoq'"
                      :rules="[
                        {
                          required: true,
                          message: $t('page.required'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="row.colorMoq"
                        :disabled="isViewPage"
                        style="width: 100%"
                        :precision="0"
                        :controls="false"
                        :min="1"
                        :max="999999"
                        clearable
                      />
                    </el-form-item>

                  </template>
                </el-table-column>
                <el-table-column
                  label="应用款式"
                  align="center"
                  :rules="{
                    required: true,
                    message: $t('page.required'),
                    trigger: ['blur','change'],
                  }"
                >
                  <template #default="{ row,$index }">
                    <el-form-item
                      :prop="'colorList.' + $index + '.moqStyleName'"
                      :rules="[
                        {
                          required: true,
                          message: $t('page.required'),
                          trigger: ['blur','change'],
                        },
                      ]"
                    >
                      <el-select
                        v-model="row.moqStyleName"
                        :disabled="isViewPage"
                        :loading="styleLoading"
                        clearable
                        filterable
                        multiple
                        @change="(val) => _queryMoqStyleIdList(val, row)"
                        @visible-change="(val) => queryMoqStyleOptions(val, row)"
                      >
                        <el-option
                          v-for="item in row.moqStyleOptions"
                          :key="item.id"
                          :value="item.styleName"
                          :label="item.styleName"
                        />
                      </el-select>
                    </el-form-item>

                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" style="text-align: center" class="dialog-footer">
        <el-button @click="$emit('input', false)">取消</el-button>
        <el-button
          type="primary"
          :loading="submitLoading"
          :disabled="isViewPage"
          @click="handleSubmit"
        >保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import {
  getLimitedStyle,
  selectColorList,
  selectInuseColorList,
  selectStyleByColorIds
} from '@/api/scm-api'
import { moqDetail, moqSave } from '@/api/configuration'
import { omit } from 'lodash'

export default {
  components: { Select },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentRow: {
      type: Object,
      default: () => ({ id: '' })
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    dialogType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      styleLoading: false,
      form: {
        styleList: [],
        colorList: [],
        styleMoq: undefined,
        colorMoq: undefined
      },
      styleOptions: [],
      colorOptions: [],
      colorInsuseOptions: [],
      styleInsuseOptions: [],
      colorSelection: [],
      styleSelection: [],
      rules: {
        styleMoq: [{ required: true, message: '必填', trigger: ['blur'] }],
        unit: { required: true, message: '必填', trigger: 'blur' },
        moqStyleName: { required: true, message: '必填', trigger: 'blur' }
      },
      searchStyleNameList: [],
      searchColorNameList: []
    }
  },
  computed: {
    isViewPage() {
      return this.dialogType === 'view'
    },
    submitParams() {
      const {
        vendorId,
        vendorName,
        colorList: color,
        styleList: style
      } = this.form
      const colorList = color.map((v) => {
        const moqStyleId = []
        v.moqStyleName.map((vv) => {
          const id = this.styleOptions.find(
            (val) => val.styleName === vv
          )?.id
          moqStyleId.push(id)
        })
        return { ...omit(v, ['moqStyleOptions']), vendorId, vendorName, moqStyleId }
      })

      const styleList = style.map((v) => {
        return { ...v, vendorId, vendorName }
      })
      return Object.assign({}, this.form, { colorList, styleList })
    },
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        // 获取详情
        const { vendorId, vendorName, state } = this.currentRow
        if (state === '0') {
          this.form = Object.assign({}, { vendorId, vendorName, unit: '1', styleList: [],
            colorList: [],
            styleMoq: undefined,
            colorMoq: undefined })
        } else {
          this._queryDetail()
        }
      } else {
        this.$refs.formRef?.clearValidate()
        this.$refs.styleFormRef?.clearValidate()
        this.$refs.colorFormRef?.clearValidate()
        this.searchColorNameList = []
        this.searchStyleNameList = []
      }
    }

  },
  mounted() {
    this._queryStyleList({ permissionFlag: false, styleStatus: '' }, true)
    this._queryStyleList({ permissionFlag: false, styleStatus: 1 })
    this._queryColorList()
    this._queryInuseColorList()
  },
  methods: {
    validateSingleStyleName(list, field, msg) {
      return {
        validator: (rule, value, cb) => {
          if (value && list.filter((v) => v[field] === value).length > 1) {
            cb(new Error(msg))
          } else {
            cb()
          }
        },
        trigger: ['blur', 'change']
      }
    },
    validateMaxColorMoq({ colorMoq, styleMoq }) {
      return {
        validator: (rule, value, cb) => {
          if (colorMoq > styleMoq) {
            cb(new Error('不得大于单款MOQ'))
          } else {
            cb()
          }
        },
        trigger: ['blur', 'change']
      }
    },
    submitForm(formUser) {
      return new Promise((resolve, reject) => {
        this.$refs[formUser].validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject(new Error('错误'))
          }
        })
      })
    },
    handleSubmit() {
      Promise.all([this.submitForm('formRef'), this.submitForm('styleFormRef'), this.submitForm('colorFormRef')])
        .then(async() => {
          try {
            this.submitLoading = true
            await moqSave(this.submitParams)
            this.$message.success('操作成功')
            this.$emit('update')
            this.$emit('input', false)
          } finally {
            this.submitLoading = false
          }
        })
        .catch(() => {
          console.log('必填校验失败')
        })
    },
    getStyleId(val, row) {
      row.styleId = this.styleOptions.find((v) => v.styleName === val)?.id
    },
    async _queryDetail() {
      try {
        this.loading = true
        const { id } = this.currentRow
        const { datas } = await moqDetail({ id })
        // 设置默认值 否则不能更新下拉数据
        datas.colorList.map(v => { v.moqStyleOptions = [] })
        this.form = datas
      } finally {
        this.loading = false
      }
    },
    async queryMoqStyleOptions(visible, row) {
      if (!visible) return
      // 默认进页面无款式下拉数据
      try {
        this.styleLoading = true
        if (!row.moqStyleOptions || !row.moqStyleOptions.length) {
          const { datas } = await selectStyleByColorIds([row.colorId])
          row.moqStyleOptions = datas
        }
      } finally {
        this.styleLoading = false
      }
    },
    async _queryMoqStyleList(colorName, row) {
      // 联动获取款式
      try {
        this.styleLoading = true
        row.moqStyleName = []
        row.moqStyleOptions = []
        row.colorId = this.colorOptions.find(
          (val) => val.colorName === colorName
        )?.id
        const { datas } = await selectStyleByColorIds([row.colorId])
        row.moqStyleOptions = datas
        // 默认选择所有款式
        row.moqStyleName = ['所有款式']
        row.moqStyleId = [-1]
      } finally {
        this.styleLoading = false
      }
    },
    _queryMoqStyleIdList(val, row) {
      const index = val.findIndex(v => v === '所有款式')
      // 选择所有款式时，若已选STyle，则取消选中已选择的Style选择项
      // 选择Style时，若选择了所有款式，则取消选中“所有款式”
      if (val.length && index === val.length - 1) {
        row.moqStyleName = ['所有款式']
      }
      if (val.includes('所有款式') && index !== val.length - 1) {
        row.moqStyleName.splice(index, 1)
      }
    },
    // 有效color下拉数据
    async _queryInuseColorList() {
      const { datas } = await selectInuseColorList()
      this.colorInsuseOptions = datas
    },
    // 用户全量/有效style
    async _queryStyleList(data, flag) {
      const { datas } = await getLimitedStyle(data)
      flag ? (this.styleOptions = datas) : (this.styleInsuseOptions = datas)
      flag && this.styleOptions.push({ styleName: '所有款式', id: -1 })
    },
    async _queryColorList() {
      const { datas } = await selectColorList()
      this.colorOptions = datas
    },
    async handleAdd(type, list) {
      if (type === 'style') {
        this.form[list].unshift({})
      } else {
        this.form[list].unshift({
          moqStyleName: [],
          moqStyleId: [],
          moqStyleOptions: []
        })
      }
    },
    async handleDelete(list, multipletable, type) {
      if (!multipletable.length) return this.$message.warning('至少选择一条数据')
      this.$confirm('该操作无法撤销，是否继续？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        multipletable.map((v) => {
          if (type === 'style') {
            const { styleName, styleMoq, colorMoq } = v
            const index = list.findIndex(
              (vv) =>
                vv.styleName === styleName &&
                styleMoq === vv.styleMoq &&
                vv.colorMoq === colorMoq
            )
            list.splice(index, 1)
          } else {
            const { colorName, moqStyleName, colorMoq } = v
            const index = list.findIndex(
              (vv) =>
                vv.colorName === colorName &&
                moqStyleName === vv.moqStyleName &&
                vv.colorMoq === colorMoq
            )
            list.splice(index, 1)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.table .el-input__inner {
  width: 140px;
}
</style>

<template>
  <div>
    <el-dialog
      title="批量导入"
      :visible.sync="visible"
      width="80%"
      :close-on-click-modal="false"
      @close="closed"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="上传文件" />
        <el-step title="数据预览" />
        <el-step title="导入数据" />
      </el-steps>

      <main class="upload-order-content">
        <section v-show="active === 0" class="import-orders">
          <div class="download-template">
            <h3 class="my-2">填写导入数据信息</h3>
            <small>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</small>
            <el-button
              class="mt-2"
              type="text"
              :loading="downloadLoding"
              @click="handleTemplate"
            >下载模板</el-button>
          </div>
          <el-divider />
          <div>
            <h3 class="my-2">上传填好的信息表</h3>
            <small>文件后缀名必须为xls
              或xlsx（即Excel格式），文件大小不得大于10M，最多不超过10000条数据</small>
            <ImportFile
              ref="uploadRef"
              :limit="1"
              class="mt-8px"
              :auto-upload="false"
              :upload-file="uploadPlanFile"
              accept=".xlsx, .xls"
              :types="['xls', 'xlsx']"
              :show-file-list="true"
              button-type="text"
              import-name="上传文件"
              @getFile="({file:val})=>file=val"
              @removeFile="handleFileRemove"
            />
          </div>
          <el-divider />

          <div class="download-template1">
            <h3 class="my-2">特别提示</h3>
            <small>导入过程中如发现个别数据校验不通过，则全量回滚修正后再重新操作导入</small>
          </div>
        </section>
        <section v-show="active === 1" class="import-orders">
          <div class="upload-error">
            <div class="upload-error--item">
              <span class="my-2">正常数量条数：</span>
              <el-link type="success" class="success-account" :underline="false">
                {{ tableInfo.successAccount || 0 }} 条
              </el-link>
            </div>
            <div class="upload-error--item">
              <span class="my-2">异常数量条数：</span>
              <el-link type="danger" class="error-account mr-2" :underline="false">
                {{ tableInfo.errorAccount || 0 }} 条
              </el-link>
              <ElButton v-show="tableInfo.errorAccount" type="text" :loading="errorLoading" @click="handleExport">下载异常数据详情</ElButton>
            </div>
          </div>
          <el-divider />
          <el-tabs v-model="activeTab" type="border-card">
            <el-tab-pane label="供应商默认MOQ" name="first">
              <vxe-table
                max-height="500px"
                align="center"
                :data="tableInfo.mainList"
                :cell-style="cellStyle"
                :row-config="{ height: 80 }"
                :scroll-y="{enabled: true, gt: 20, mode: 'wheel' }"
              >
                <vxe-table-column width="90" type="seq" title="序号" />
                <vxe-table-column v-for="(item,index) in getColumns(activeTab)" :key="index" min-width="100" :field="item.field" :title="item.title" />
              </vxe-table>
            </el-tab-pane>
            <el-tab-pane label="特定款式" name="second">
              <vxe-table
                max-height="500px"
                align="center"
                :data="tableInfo.styleList"
                :cell-style="cellStyle"
                :row-config="{ height: 80 }"
                :scroll-y="{enabled: true, gt: 20, mode: 'wheel' }"
              >
                <vxe-table-column width="90" type="seq" title="序号" />
                <vxe-table-column v-for="(item,index) in getColumns(activeTab)" :key="index" min-width="100" :field="item.field" :title="item.title" />
              </vxe-table>
            </el-tab-pane>
            <el-tab-pane label="特定颜色" name="third">
              <vxe-table
                max-height="500px"
                align="center"
                :data="tableInfo.colorList"
                :cell-style="cellStyle"
                :row-config="{ height: 80 }"
                :scroll-y="{enabled: true, gt: 20, mode: 'wheel' }"
              >
                <vxe-table-column width="90" type="seq" title="序号" />
                <vxe-table-column v-for="(item,index) in getColumns(activeTab)" :key="index" min-width="100" :field="item.field" :title="item.title" />
              </vxe-table>
            </el-tab-pane>
          </el-tabs>

        </section>
        <section v-show="active === 2" class="data-review">
          <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage" />
          <p
            v-if="percentage"
            class="mt-16px"
            style="text-align: center"
          >正在导入数据，请勿关闭或刷新页面</p>
        </section>
        <section v-show="active === 3" style="text-align: center">
          <el-result icon="success" title="批量导入完成" :sub-title="`您已成功导入${tableInfo.tableList.length}条数据`" />
        </section>
      </main>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-show="active === 0" @click="closed">取消</el-button>
          <el-button v-show="active === 1" @click="handleToReview('previous')">上一步</el-button>
          <el-button
            v-show="active === 0"
            type="primary"
            :loading="loading"
            @click="handleToReview('next')"
          >下一步</el-button>
          <el-button
            v-show="active === 1"
            type="primary"
            :loading="loading"
            @click="handleToReview('submit')"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ImportFile from '@/components/ImportFile'
import { downloads } from '@/utils'
import { trackDialogEvent, handleBreadTitle } from '@/utils/monitor'
import { moqImportTemplate, moqPreview, moqImportData } from '@/api/configuration'

export default {
  components: { ImportFile },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: 'first',
      file: '',
      fileList: [],
      active: 0,
      downloadLoding: false,
      loading: false,
      errorLoading: false,
      percentage: 0,
      importError: false,
      tableInfo: {
        colorList: [],
        styleList: [],
        mainList: [],
        tableList: [],
        successAccount: 0,
        errorAccount: 0
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.file = ''
        this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      } else {
        trackDialogEvent(handleBreadTitle(this.$route) + '批量导入')
      }
    },
    'active'(val) {
      if (val !== 2) {
        this.importError = false
      }
    }
  },
  methods: {
    handleMsg(errorMsgList, tipList) {
      const errorMsg = errorMsgList ? errorMsgList.join(';') : ''
      const tipMsg = tipList || ''
      return errorMsg + (errorMsg && tipMsg ? `；${tipMsg}` : tipMsg)
    },

    getColumns(currentTab) {
      switch (currentTab) {
        case 'first':
          return [
            { field: 'errorMsg', title: '错误信息' },
            { field: 'vendorName', title: '供应商' },
            { field: 'styleMoq', title: '单款默认MOQ' },
            { field: 'colorMoq', title: '单色默认MOQ' },
            { field: 'remark', title: '备注说明' }
          ]
        case 'second':
          return [{ field: 'errorMsg', title: '错误信息' },
            { field: 'vendorName', title: '供应商' },
            { field: 'styleName', title: 'Style' },
            { field: 'styleMoq', title: '单款MOQ' },
            { field: 'colorMoq', title: '单色MOQ' }]
        case 'third':
          return [
            { field: 'errorMsg', title: '错误信息' },
            { field: 'vendorName', title: '供应商' },
            { field: 'colorName', title: 'Color' },
            { field: 'colorMoq', title: '单色MOQ' },
            { field: 'moqStyleName', title: '应用款式' }]
      }
    },
    async handleTemplate() {
      try {
        this.downloadLoding = true
        const { datas } = await moqImportTemplate()
        downloads(datas)
      } finally {
        this.downloadLoding = false
      }
    },

    handleFileRemove() {
      this.$refs.uploadRef.$refs.uploadForm.clearFiles()
    },
    async uploadPlanFile() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)
        const { code, datas } = await moqPreview(formData)
        if (code === 0) {
          const updateList = (list, message) => {
            list.forEach(v => {
              if (v.orangeFlag) {
                v.tipList = message
              }
              v.errorMsg = this.handleMsg(v.errorMsgList, v.tipList)
            })
          }

          updateList(datas.mainList, '当前供应商已存在默认MOQ配置，确认提交将覆盖原有配置，是否继续')
          updateList(datas.colorList, '当前供应商+Color已存在默认MOQ配置，确认提交将覆盖原有配置，是否继续')
          updateList(datas.styleList, '当前供应商+Style已存在特定款式MOQ配置，确认提交将覆盖原有配置，是否继续')

          this.tableInfo = {
            ...datas,
            tableList: Object.values(datas).flat()
          }

          const successAccount = this.tableInfo.tableList.filter(item => !item.errorMsg).length
          this.tableInfo.successAccount = successAccount
          this.tableInfo.errorAccount = this.tableInfo.tableList.length - successAccount

          this.file = ''
          this.fileList = []
          this.active++
        }
      } catch (e) {
        console.log('e: ', e)
      } finally {
        this.loading = false
        this.$refs.upload.$refs.uploadForm.clearFiles()
      }
    },
    handleToReview(type) {
      this.currentStatus(type)
    },
    closed() {
      this.file = ''
      this.active = 0
      this.$emit('input', false)
    },
    currentStatus(type) {
      if (type === 'next') {
        if (this.active === 0) {
          if (!this.file) return this.$message.warning('请上传文件')
          this.$refs.uploadRef.$refs.uploadForm.submit()
        }
      }
      if (type === 'previous') {
        this.active--
      }
      if (type === 'submit') {
        if (this.tableInfo.tableList.some((item) => item.errorMsgList && item.errorMsgList.length)) { return this.$message.warning('当前预览数据存在错误，无法提交') }
        this.handleImport()
        this.active = 2
      }
      if (type === 'success') {
        this.active = 3
        this.$emit('refresh')
      }
    },
    async handleImport() {
      try {
        this.loading = true
        this.active = 3
        const { mainList, colorList, styleList } = this.tableInfo
        const { code } = await moqImportData({ mainList, colorList, styleList })
        if (code === 0) {
          // 提示成功页面
          this.percentage = 100
          this.currentStatus('success')
        }
      } catch (e) {
        this.percentage = 0
        this.importError = true
      } finally {
        this.loading = false
      }
    },
    // 下载错误信息表格
    async handleExport() {
      try {
        this.errorLoading = true

        import('@/vendor/Export2Excel').then((excel) => {
          var excelDatas = [
            {
              tHeader: ['序号', '错误信息', '供应商', '单款默认MOQ', '单色默认MOQ', '备注说明'], // sheet表一头部
              filterVal: ['no', 'errorMsg', 'vendorName', 'styleMoq', 'colorMoq', 'remark'], // 表一的数据字段
              tableDatas: this.tableInfo.mainList, // 表一的整体json数据
              sheetName: '供应商默认MOQ'// 表一的sheet名字
            },
            {
              tHeader: ['序号', '错误信息', '供应商', 'Style', '单款默认MOQ', '单色默认MOQ'], // sheet表一头部
              filterVal: ['no', 'errorMsg', 'vendorName', 'styleName', 'styleMoq', 'colorMoq'], // 表一的数据字段
              tableDatas: this.tableInfo.styleList, // 表一的整体json数据
              sheetName: '特定款式'
            },
            {
              tHeader: ['序号', '错误信息', '供应商', 'Color', '单色默认MOQ', '应用款式'], // sheet表一头部
              filterVal: ['no', 'errorMsg', 'vendorName', 'styleName', 'colorMoq', 'moqStyleName'], // 表一的数据字段
              tableDatas: this.tableInfo.colorList,
              sheetName: '特定颜色'
            }
          ]
          var tHeader = []
          var dataArr = []
          var sheetnames = []
          for (var i in excelDatas) {
            tHeader.push(excelDatas[i].tHeader)
            dataArr.push(this.formatJson(excelDatas[i].filterVal, excelDatas[i].tableDatas))
            sheetnames.push(excelDatas[i].sheetName)
          }
          excel.export_json_to_excel_sheet({
            header: tHeader,
            data: dataArr,
            sheetname: sheetnames,
            filename: `MOQ导入异常数据列表`
          })
          setTimeout(() => {
            this.errorLoading = false
          }, 1000)
        })
      } catch (err) {
        this.errorLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        } else {
          return v[j]
        }
      }))
    },
    cellStyle({ row, column }) {
      if (row.errorMsg) {
        if (row.errorMsgList && row.errorMsgList.length) {
          return {
            color: 'red'
          }
        } else {
          return {
            color: 'orange'
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upload-dialog /deep/ {
    .el-step__title.is-success, .el-step__head.is-success , .el-step__description.is-success,
    .el-step__title.is-process, .el-step__head.is-process , .el-step__description.is-process{
    color: #1890ff;
    border-color: #1890ff;
  }
}
.upload-order-content {
  width: 90%;
  padding: 50px 0px;
  margin: 0 auto;

  .download-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .download-template1 {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    align-items: flex-start;
    background: rgb(255, 245, 230);
  }

  .upload-error--item {
    display: flex;
    align-items: center;
  }

  .upload-order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .el-upload--text {
      order: 2;
    }

    .el-upload__tip {
      order: 1;
    }

    .el-upload-list.el-upload-list--text {
      order: 3;
    }
  }
}
</style>

